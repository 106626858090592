<template>
  <div style="width: 100%; height: 100%">
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
#__nuxt {
  width: 100%;
  height: 100%;
}
</style>
