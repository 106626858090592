
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "buildInfo": {
    "version": "0.1.6",
    "time": 1719654150781,
    "commit": "8935567df04bf30b9faf6c4a86d9b1191396d346",
    "shortCommit": "8935567"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
