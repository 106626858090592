import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAutoComplete, LazyCalendar, LazyCascadeSelect, LazyCheckbox, LazyChips, LazyColorPicker, LazyDatePicker, LazyDropdown, LazyFloatLabel, LazyIconField, LazyInputChips, LazyInputGroup, LazyInputGroupAddon, LazyInputIcon, LazyInputMask, LazyInputNumber, LazyInputOtp, LazyInputSwitch, LazyInputText, LazyKnob, LazyListbox, LazyMultiSelect, LazyPassword, LazyRadioButton, LazyRating, LazySelect, LazySelectButton, LazySlider, LazyTextarea, LazyToggleButton, LazyToggleSwitch, LazyTreeSelect, LazyButton, LazyButtonGroup, LazySpeedDial, LazySplitButton, LazyColumn, LazyRow, LazyColumnGroup, LazyDataTable, LazyDataView, LazyOrderList, LazyOrganizationChart, LazyPaginator, LazyPickList, LazyTree, LazyTreeTable, LazyTimeline, LazyVirtualScroller, LazyAccordion, LazyAccordionPanel, LazyAccordionHeader, LazyAccordionContent, LazyAccordionTab, LazyCard, LazyDeferredContent, LazyDivider, LazyFieldset, LazyPanel, LazyScrollPanel, LazySplitter, LazySplitterPanel, LazyStepper, LazyStepList, LazyStep, LazyStepItem, LazyStepPanels, LazyStepPanel, LazyStepperPanel, LazyTabView, LazyTabs, LazyTabList, LazyTab, LazyTabPanels, LazyTabPanel, LazyToolbar, LazyConfirmDialog, LazyConfirmPopup, LazyDialog, LazyDrawer, LazyDynamicDialog, LazyOverlayPanel, LazyPopover, LazySidebar, LazyFileUpload, LazyBreadcrumb, LazyContextMenu, LazyDock, LazyMenu, LazyMenubar, LazyMegaMenu, LazyPanelMenu, LazySteps, LazyTabMenu, LazyTieredMenu, LazyMessage, LazyInlineMessage, LazyToast, LazyCarousel, LazyGalleria, LazyImage, LazyAvatar, LazyAvatarGroup, LazyBadge, LazyBlockUI, LazyChip, LazyInplace, LazyMeterGroup, LazyOverlayBadge, LazyScrollTop, LazySkeleton, LazyProgressBar, LazyProgressSpinner, LazyTag, LazyTerminal } from '#components'
const lazyGlobalComponents = [
  ["AutoComplete", LazyAutoComplete],
["Calendar", LazyCalendar],
["CascadeSelect", LazyCascadeSelect],
["Checkbox", LazyCheckbox],
["Chips", LazyChips],
["ColorPicker", LazyColorPicker],
["DatePicker", LazyDatePicker],
["Dropdown", LazyDropdown],
["FloatLabel", LazyFloatLabel],
["IconField", LazyIconField],
["InputChips", LazyInputChips],
["InputGroup", LazyInputGroup],
["InputGroupAddon", LazyInputGroupAddon],
["InputIcon", LazyInputIcon],
["InputMask", LazyInputMask],
["InputNumber", LazyInputNumber],
["InputOtp", LazyInputOtp],
["InputSwitch", LazyInputSwitch],
["InputText", LazyInputText],
["Knob", LazyKnob],
["Listbox", LazyListbox],
["MultiSelect", LazyMultiSelect],
["Password", LazyPassword],
["RadioButton", LazyRadioButton],
["Rating", LazyRating],
["Select", LazySelect],
["SelectButton", LazySelectButton],
["Slider", LazySlider],
["Textarea", LazyTextarea],
["ToggleButton", LazyToggleButton],
["ToggleSwitch", LazyToggleSwitch],
["TreeSelect", LazyTreeSelect],
["Button", LazyButton],
["ButtonGroup", LazyButtonGroup],
["SpeedDial", LazySpeedDial],
["SplitButton", LazySplitButton],
["Column", LazyColumn],
["Row", LazyRow],
["ColumnGroup", LazyColumnGroup],
["DataTable", LazyDataTable],
["DataView", LazyDataView],
["OrderList", LazyOrderList],
["OrganizationChart", LazyOrganizationChart],
["Paginator", LazyPaginator],
["PickList", LazyPickList],
["Tree", LazyTree],
["TreeTable", LazyTreeTable],
["Timeline", LazyTimeline],
["VirtualScroller", LazyVirtualScroller],
["Accordion", LazyAccordion],
["AccordionPanel", LazyAccordionPanel],
["AccordionHeader", LazyAccordionHeader],
["AccordionContent", LazyAccordionContent],
["AccordionTab", LazyAccordionTab],
["Card", LazyCard],
["DeferredContent", LazyDeferredContent],
["Divider", LazyDivider],
["Fieldset", LazyFieldset],
["Panel", LazyPanel],
["ScrollPanel", LazyScrollPanel],
["Splitter", LazySplitter],
["SplitterPanel", LazySplitterPanel],
["Stepper", LazyStepper],
["StepList", LazyStepList],
["Step", LazyStep],
["StepItem", LazyStepItem],
["StepPanels", LazyStepPanels],
["StepPanel", LazyStepPanel],
["StepperPanel", LazyStepperPanel],
["TabView", LazyTabView],
["Tabs", LazyTabs],
["TabList", LazyTabList],
["Tab", LazyTab],
["TabPanels", LazyTabPanels],
["TabPanel", LazyTabPanel],
["Toolbar", LazyToolbar],
["ConfirmDialog", LazyConfirmDialog],
["ConfirmPopup", LazyConfirmPopup],
["Dialog", LazyDialog],
["Drawer", LazyDrawer],
["DynamicDialog", LazyDynamicDialog],
["OverlayPanel", LazyOverlayPanel],
["Popover", LazyPopover],
["Sidebar", LazySidebar],
["FileUpload", LazyFileUpload],
["Breadcrumb", LazyBreadcrumb],
["ContextMenu", LazyContextMenu],
["Dock", LazyDock],
["Menu", LazyMenu],
["Menubar", LazyMenubar],
["MegaMenu", LazyMegaMenu],
["PanelMenu", LazyPanelMenu],
["Steps", LazySteps],
["TabMenu", LazyTabMenu],
["TieredMenu", LazyTieredMenu],
["Message", LazyMessage],
["InlineMessage", LazyInlineMessage],
["Toast", LazyToast],
["Carousel", LazyCarousel],
["Galleria", LazyGalleria],
["Image", LazyImage],
["Avatar", LazyAvatar],
["AvatarGroup", LazyAvatarGroup],
["Badge", LazyBadge],
["BlockUI", LazyBlockUI],
["Chip", LazyChip],
["Inplace", LazyInplace],
["MeterGroup", LazyMeterGroup],
["OverlayBadge", LazyOverlayBadge],
["ScrollTop", LazyScrollTop],
["Skeleton", LazySkeleton],
["ProgressBar", LazyProgressBar],
["ProgressSpinner", LazyProgressSpinner],
["Tag", LazyTag],
["Terminal", LazyTerminal],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
