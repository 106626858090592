import payload_plugin_Est7n9pJCj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.2_firebase@10.12.2_magicast@0.3.4_rollup@4.18.0_vu_owsrswg6u4rnwbqkaiiy23ihhy/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_QNZtU7vxnF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@libsql+client@0.7.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@2_gm6h4ae3zehumf3jphifg4vvma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_20gW1fAZQ3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@libsql+client@0.7.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@2_gm6h4ae3zehumf3jphifg4vvma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rA1kOSisaE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@libsql+client@0.7.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@2_gm6h4ae3zehumf3jphifg4vvma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_N0pVb8pRWX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@libsql+client@0.7.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@2_gm6h4ae3zehumf3jphifg4vvma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/SSTAlumniAssociation.App/.nuxt/components.plugin.mjs";
import prefetch_client_HPyPIMiZW0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@libsql+client@0.7.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@2_gm6h4ae3zehumf3jphifg4vvma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_bSmCCJKEPA from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_xenakqhmjnzz5n7akvip4wqzxq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_uRl5t0Jg3v from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_xenakqhmjnzz5n7akvip4wqzxq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import unocss_MzCDxu9LMj from "/opt/buildhome/repo/SSTAlumniAssociation.App/.nuxt/unocss.mjs";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/SSTAlumniAssociation.App/.nuxt/primevue-plugin.mjs";
import plugin_client_HQtR93MxDG from "/opt/buildhome/repo/node_modules/.pnpm/@primevue+nuxt-module@4.0.0-rc.3_@babel+parser@7.24.7_@primeuix+utils@0.0.5_magicast@0.3.4_ro_tdkz74ttztccnwosyhkmoseqtq/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_client_ZvvYvEtB3k from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.2_firebase@10.12.2_magicast@0.3.4_rollup@4.18.0_vu_owsrswg6u4rnwbqkaiiy23ihhy/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/SSTAlumniAssociation.App/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/SSTAlumniAssociation.App/.nuxt/vuefire-plugin.mjs";
import plugin_client_bKdzZ9vZUO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.2_firebase@10.12.2_magicast@0.3.4_rollup@4.18.0_vu_owsrswg6u4rnwbqkaiiy23ihhy/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.mjs";
import chunk_reload_client_KnZUBajELQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@libsql+client@0.7.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@2_gm6h4ae3zehumf3jphifg4vvma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import growthbook_Iy8w9k9ghu from "/opt/buildhome/repo/SSTAlumniAssociation.App/plugins/growthbook.ts";
import new_relic_YkNTvcbZxf from "/opt/buildhome/repo/SSTAlumniAssociation.App/plugins/new-relic.ts";
import vue_query_wrmMkNpEpe from "/opt/buildhome/repo/SSTAlumniAssociation.App/plugins/vue-query.ts";
export default [
  payload_plugin_Est7n9pJCj,
  revive_payload_client_QNZtU7vxnF,
  unhead_20gW1fAZQ3,
  router_rA1kOSisaE,
  payload_client_N0pVb8pRWX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HPyPIMiZW0,
  pwa_icons_bSmCCJKEPA,
  pwa_client_uRl5t0Jg3v,
  unocss_MzCDxu9LMj,
  primevue_plugin_egKpok8Auk,
  plugin_client_HQtR93MxDG,
  plugin_client_ZvvYvEtB3k,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_bKdzZ9vZUO,
  chunk_reload_client_KnZUBajELQ,
  growthbook_Iy8w9k9ghu,
  new_relic_YkNTvcbZxf,
  vue_query_wrmMkNpEpe
]