import { default as _91id_93g76JTDoGvGMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/events/[id].vue?macro=true";
import { default as index9IfZ5G6zSuMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/events/index.vue?macro=true";
import { default as indexIWQDmgjgccMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/index.vue?macro=true";
import { default as index5j7IlgWc7nMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/members/index.vue?macro=true";
import { default as adminOlKNzm35goMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin.vue?macro=true";
import { default as _91_46_46_46slug_937yCcmXgr9CMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/app/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_932uZO0ZxuQbMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/guard/[...slug].vue?macro=true";
import { default as indexvVfuiANa8WMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/index.vue?macro=true";
import { default as _91admissionKey_93SeRUxHgYWTMeta } from "/opt/buildhome/repo/SSTAlumniAssociation.App/pages/pass/[admissionKey].vue?macro=true";
export default [
  {
    name: adminOlKNzm35goMeta?.name,
    path: "/admin",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-events-id",
    path: "events/:id()",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/events/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-events",
    path: "events",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/events/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-members",
    path: "members",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/admin/members/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app-slug",
    path: "/app/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/app/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "guard-slug",
    path: "/guard/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/guard/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pass-admissionKey",
    path: "/pass/:admissionKey()",
    component: () => import("/opt/buildhome/repo/SSTAlumniAssociation.App/pages/pass/[admissionKey].vue").then(m => m.default || m)
  }
]